import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import AuthenticationService from '../api/AuthenticationService';

function Header (props){    

    const [orgname, setOrgname] = useState('');
    const [orgid, setOrgid] = useState('');

    useEffect(
        ()=>{
            setTimeout(() => {
                setOrgname(sessionStorage.getItem('authenticatedUser'))
                setOrgid(sessionStorage.getItem('authenticatedUserId'))
            }, 333);
        },[orgname]
    )

    return (
        <header>
            <nav className="navbar navbar-expand-md navbar-dark bg-dark">
                <div>{<Link className="nav-link" to="/welcome">《公安研究》订阅管理系统</Link>}
                </div>
                <ul className="navbar-nav">
                    <li><Link className="nav-link" to="/orders">订单列表</Link></li>
                </ul>
                <ul className="navbar-nav navbar-collapse justify-content-end">
                    <li><Link className="nav-link" to={{
                        pathname: "/organization/view",
                        orgid: orgid
                        }}>单位信息</Link></li>
                    <li><Link className="nav-link" to="/logout" 
                        onClick={AuthenticationService.logout}>离开</Link></li>
                </ul>
            </nav>
        </header>
    )
}

export default Header