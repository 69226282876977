import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

const AlertConfirmOrderDelete = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true)
    console.log('openopen')
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleNo = () => {
    setOpen(false)
  }

  const handleYes = () => {
      setOpen(false)
      props.handleContinue()
  }

  return (
    <>
        
        <IconButton
            color="primary"
            onClick={handleClickOpen}
        >
            <DeleteIcon/>
        </IconButton>
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  handleClose();
                } 
            }}        >
            <DialogTitle id="alert-dialog-title">
                {"确定删除此订单?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                (此操作不可撤销)
              </DialogContentText>
            </DialogContent>
            
            <DialogActions>
                <Button onClick={handleNo}>否</Button>
                <Button onClick={handleYes} autoFocus>
                    是
                </Button>
            </DialogActions>
        </Dialog>
    </>
  );
}


export default AlertConfirmOrderDelete