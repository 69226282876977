import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import PayOrderDataService from '../api/PayOrderDataService';
import OrderDataService from '../api/OrderDataService';
import QRCode from 'qrcode.react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const DialogWXPayQRCode = (props) => {
  const [open, setOpen] = React.useState(false);
  const [orderNo, setOrderNo] = React.useState(props.orderNo);
  const [qrcodeValue, setQrcodeValue] = React.useState("")
  const history = useHistory();

  const handleClickOpen = () => {
    setOpen(true)
    OrderDataService.getWXPayQRCode(orderNo).then(
      (response)=>{
      if(response.data === 'Nada'){
        //do nothing
      }else{
        setQrcodeValue(response.data)
        console.log(response.data)
      }
      
    })
    
  }

  const handleClose = () => {
    setOpen(false)
  }


  const handleYes = () => {
      setOpen(false)
      //props.handleContinue()
      OrderDataService.checkWXPayStatus(orderNo)
      // history.push({
      //   pathname: "/orders",
      //   state:{
      //     orderid: "2426b56d-8695-c87a-6aa3-1a665fef5660"
      //   }
      // })
      //alert(window.location)
      const wlocation = window.location.toString()
      if (wlocation.endsWith("/view")){
        history.push({
            pathname: "/orders",
          })
        history.go(0)
      }else if (wlocation.endsWith("/orders")){
        window.location.reload()
      }
    }


  return (
    <>
        
        <button
            onClick={handleClickOpen}
        >
          微信支付
        </button>
        <Dialog
            open={open}
            aria-labelledby="wxpay-qrcode-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown={true}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  handleClose();
                } 
            }}        >
            <DialogTitle id="wxpay-qrcode-dialog-title">
                {"确定使用微信支付？"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                如果此订单已通过其他渠道支付过，不必再次支付。<br/>
                {orderNo + ":《公安研究》杂志"}
              </DialogContentText>
              
              {qrcodeValue ? (<QRCode value={qrcodeValue} />) : (<p><br/><br/><br/>正在生成微信支付二维码...</p>)}
              
            </DialogContent>
            
            <DialogActions>
                <Button onClick={handleYes} autoFocus>
                    返回
                </Button>
            </DialogActions>
        </Dialog>
    </>
  );
}


export default DialogWXPayQRCode