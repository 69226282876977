import React, { useState, useEffect } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import OrderDataService from '../api/OrderDataService'
import AlertConfirmOrderSubmit from './AlertConfirmOrderSubmit'
import DialogWXPayQRCode from './DialogWXPayQRCode';



function OrderView (props){

    const [order, setOrder] = useState({})

    useEffect(
        ()=>{
            let obj = ((props.location.orderid === undefined) ? props.location.state : props.location.orderid )
            console.log('OrderView useEffect start :' + obj)

            OrderDataService.retrieveOrder(obj.orderid)
                .then(
                    response => {

                        setOrder(response.data)
                        console.log(response.data)
                    }
                )
        },[props.location]
    )

    const updateOrder = () => {
        OrderDataService.retrieveOrder(order.orderid)
                .then(
                    response => {

                        setOrder(response.data)
                        console.log(response.data)
                    }
                )
    }

    let i = 1

    return (
        <>
            <div className="container">
                <h3>发票信息</h3>

                <Table className="table">
                    <TableBody>
                        <TableRow>
                            <TableCell className="label" style={{ width: "20%"}}>是否开票</TableCell>
                            <TableCell className="field" colSpan={3}> {order.need_invoice} </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="label" style={{ width: "20%"}}>购货单位全称</TableCell>
                            <TableCell className="field" style={{ width: "30%"}}>{order.org_full_name}</TableCell>
                            <TableCell className="label" style={{ width: "20%"}}>购货单位纳税人识别号</TableCell>
                            <TableCell className="field" style={{ width: "30%"}}>{order.org_TAX_id}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="label" style={{ width: "20%"}}>购货单位地址和电话</TableCell>
                            <TableCell className="field" colSpan={3}> {order.org_address + ' ' + order.org_telephone} </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="label" style={{ width: "20%"}}>购货单位开户行及账号</TableCell>
                            <TableCell className="field" colSpan={3}> {order.org_bank_name + ' ' + order.org_bank_account} </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="label" style={{ width: "20%"}}>互联网邮箱</TableCell>
                            <TableCell className="field" colSpan={3}> {order.org_email} </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
                <br/>
            </div>

            <div className="container">
                <h3>邮寄信息</h3>
                <Table className="table">
                    <TableBody>
                        <TableRow>
                            <TableCell className="label" style={{ width: "10%"}}>省</TableCell>
                            <TableCell className="field" style={{ width: "15%"}}>{order.org_province}</TableCell>
                            <TableCell className="label" style={{ width: "10%"}}>市</TableCell>
                            <TableCell className="field" style={{ width: "15%"}}>{order.org_city}</TableCell>
                            <TableCell className="label" style={{ width: "10%"}}>收货地址</TableCell>
                            <TableCell className="field" style={{ width: "40%"}}>{order.postinfo_receiver_address}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="label">收货单位</TableCell>
                            <TableCell className="field" colSpan={3}>{order.postinfo_receiver_org}</TableCell>
                            <TableCell className="label">收货人</TableCell>
                            <TableCell className="field" colSpan={3}>{order.postinfo_receiver_name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="label">联系电话</TableCell>
                            <TableCell className="field" colSpan={3}>{order.postinfo_telephone}</TableCell>
                            <TableCell className="label">邮编</TableCell>
                            <TableCell className="field" colSpan={3}>{order.postinfo_postcode}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <br/>
            </div>

            <div className="container">
                <h3>购货信息</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th>序号</th>
                            <th>商品名称</th>
                            <th>单价</th>
                            <th>数量</th>
                            <th>金额</th>
                            <th>备注</th>
                        </tr>
                    </thead>

                    {
                    typeof(order.order_lineitems) != "undefined" &&
                    
                    <tbody>
                        {
                            order.order_lineitems.sort((a,b)=>(Date.parse(a.zm_creation_time) - Date.parse(b.zm_creation_time))).map(
                                lineitem => 
                                    <tr key={lineitem.id}>
                                        <td>{i++}</td>
                                        <td>{lineitem.product}</td>
                                        <td>{lineitem.price}</td>
                                        <td>{lineitem.quantity}</td>
                                        <td>{lineitem.amount}</td>
                                        <td>{lineitem.note}</td>
                                    </tr>
                            )
                        }
                    </tbody>
                    }
                </table>
                订单总金额：{order.total_amount} 元
            
                <br/>
                <br/>
                <h3>付款备注</h3>
                <div className="container">
                    

                    <Table className="table">
                        <TableBody>
                            <TableRow>
                                <TableCell className="label" style={{ width: "10%"}}>付款人</TableCell>
                                <TableCell className="field" style={{ width: "15%"}}>{order.payer}</TableCell>
                                <TableCell className="label" style={{ width: "10%"}}>付款日期</TableCell>
                                <TableCell className="field" style={{ width: "15%"}}>{moment(order.pay_date).format('YYYY-MM-DD')}</TableCell>
                                <TableCell className="label" style={{ width: "10%"}}>备注</TableCell>
                                <TableCell className="field" style={{ width: "40%"}}>{order.note}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
                <br/>
                
                {order.status === '草稿' && 
                <Link to={{
                    pathname: "/order/edit",
                    orderid: order.id
                    }}>
                    <Button variant="outlined" color="primary">编辑</Button>
                </Link>}
                
                {order.status === '草稿' && 
                <AlertConfirmOrderSubmit handleContinue={
                    ()=>{
                        order.status="已提交"
                        order.zm_submission_time = new Date()
                        console.log("submit in view page : " + order.submissionTime)
                        OrderDataService.updateOrder(order)
                            .then((response) => props.history.push({
                                pathname: '/order/view/',
                                state:{
                                    orderid: response.data
                                }
                            } ))
                    }}/>}

                {order.status === '已提交' && order.wechat_pay_status === '已支付' &&
                <h3>订单状态：已提交（已通过微信支付完成付款）</h3>}
                {order.status === '已提交' && order.wechat_pay_status !== '已支付' &&
                <h3>订单状态：已提交</h3>} &nbsp;&nbsp;&nbsp; 
                {(order.status === "已提交") && (order.isScanPayable === 1) && (order.wechat_pay_status !== "已支付") &&
                <DialogWXPayQRCode 
                    handleContinue={()=>{
                    }}
                    orderNo = {order.order_no}      
                />
            }
                <br/><br/>
            </div>
        </>
    )
}

export default OrderView





