import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const AlertConfirmOrderSubmit = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleContinue = () => {
      setOpen(false)
      props.handleContinue()
  }

  return (
    <>
        <Button variant="outlined" onClick={handleClickOpen}>
            提交订单
        </Button>
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  handleClose();
                } 
            }}        >
            <DialogTitle id="alert-dialog-title">
                {"确定提交此订单?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    提交订单后，订单信息将无法再次修改。<br/>
                    
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>取消</Button>
                <Button onClick={handleContinue} autoFocus>
                    提交
                </Button>
            </DialogActions>
        </Dialog>
    </>
  );
}


export default AlertConfirmOrderSubmit