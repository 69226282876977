//import '@babel/polyfill'
//import 'react-app-polyfill/ie9'
//import 'react-app-polyfill/ie11'
//import 'react-app-polyfill/stable'
import './App.css';
import './bootstrap.css'
import Login from './components/Login'
import {HashRouter as Router, Route, Switch} from 'react-router-dom'
import Welcome from './components/Welcome';
import Header from './components/Header';
import OrganizationView from './components/OrganizationView';
import OrganizationNew from './components/OrganizationNew';
import OrganizationEdit from './components/OrganizationEdit';
import OrderList from './components/OrderList';
import OrderView from './components/OrderView';
import OrderEdit from './components/OrderEdit';
import OrderNew from './components/OrderNew';


function App() {
  return (
    <Router>
        <>
            <Route exact={true} path="/"       component={Login}/>
            <Route exact={true} path="/welcome" component={Header}/>
            <Route path="/organization" component={Header}/>
            <Route path="/orders" component={Header}/>
            <Route path="/order/view" component={Header}/>
            <Route path="/order/edit" component={Header}/>
            <Route path="/order/new" component={Header}/>

            <Switch>
                <Route exact={true} path="/logout" component={Login}/>
                <Route exact={true} path="/login"  component={Login}/>
                <Route path="/register" component={OrganizationNew}/>

                <Route exact={true} path="/welcome"  component={Welcome}/>
                <Route exact={true} path="/organization/view/" component={OrganizationView}/>
                <Route exact={true} path="/organization/edit" component={OrganizationEdit}/>
                
                <Route path="/orders" component={OrderList}/>

                <Route exact={true} path="/order/view" component={OrderView}/>
                <Route exact={true} path="/order/edit" component={OrderEdit}/>
                <Route exact={true} path="/order/new" component={OrderNew}/>


            </Switch>
        </>
    </Router>
  );
}

export default App;
