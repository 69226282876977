import axios from 'axios'
import { JPA_API_URL } from '../Constants';
import Base64 from './Base64'

class PayOrderDataService {
    createBasicAuthToken(username,password) {
        // return 'Basic ' + window.btoa(unescape(encodeURIComponent(username + ":" + password)))
        return 'Basic ' + Base64.encode(username + ":" + password)
    }

    createPayOrder(payOrder) {
        //console.log('executed service')
        return axios.post(`${JPA_API_URL}/scanpay/add`, payOrder, 
            {headers: {authorization: this.createBasicAuthToken(
                sessionStorage.getItem('authenticatedUser'),
                sessionStorage.getItem('authenticatedUserPassword'),
            )}});
    }


}

export default new PayOrderDataService()
