import React,{Component} from 'react'
import imgURL from '../bg.png'
import AuthenticationService from '../api/AuthenticationService'
import DialogFindBackPassword from './DialogFindBackPassword'
import DialogWebsiteInstruction from './DialogWebsiteInstruction'

class Login extends Component{
    constructor(props){
        super(props)

        this.state = {
            username: "",
            password: "",
            hasLoginFailed:false,
            isUsernameChinese:false
        }

        this.handleChange = this.handleChange.bind(this)
        this.loginClicked = this.loginClicked.bind(this)
        this.registerClicked = this.registerClicked.bind(this)
        this.handlePWDKeyDown = this.handlePWDKeyDown.bind(this)
    }

    render(){
        return (
            <div className="login_container">
                <br/>
                <img className="bg_img" src={imgURL} alt="背景图"></img>
                <br/><br/>
                单位名称：<input type="text" name="username" style={{ width: '360px'}} placeholder="您的工作单位全称" value={this.state.username} onChange={this.handleChange}></input>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                登录密码：<input type="password" name="password" value={this.state.password} onChange={this.handleChange} onKeyDown={this.handlePWDKeyDown}></input>
                &nbsp;&nbsp;&nbsp;
                <button className="btn btn-success" onClick={this.loginClicked}>登录</button>

                &nbsp;&nbsp;&nbsp;
                <button className="btn btn-success" onClick={this.registerClicked}>注册</button>
                &nbsp;&nbsp;&nbsp;

                <DialogFindBackPassword>找回密码</DialogFindBackPassword>

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                <DialogWebsiteInstruction>操作说明</DialogWebsiteInstruction>

                {this.state.hasLoginFailed && this.state.isUsernameChinese && <div className="alert alert-warning">您输入的是无效的单位名称或密码</div>}
                {this.state.hasLoginFailed && !this.state.isUsernameChinese && <div className="alert alert-warning">请输入中文的单位名称（需要与注册时填写的名称一致）</div>}

                <br/><br/><br/><br/><br/>
                本站域名：www.gayj.org.cn 
                <br/>
                ICP备案号:<a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2021030057号</a>
                <br/><br/><br/>
            </div>
        )
    }

    handlePWDKeyDown(event){
        if (event.key === 'Enter'){
            this.loginClicked()
        }
    }

    handleChange(event){
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    loginClicked(){
        sessionStorage.clear()
        console.log('session storage cleared')
        console.log('login clicked')
        const chineseRegex = /^[\u4e00-\u9fa5（）()]+$/;
        if( chineseRegex.test(this.state.username)){
            console.log("Chinese org name verified.")
            this.state.isUsernameChinese = true
        }else{
            this.state.isUsernameChinese = false
        }
        AuthenticationService.executeBasicAuthenticationService(this.state.username, this.state.password)
            .then((response) => {

                if (response.status === 200) {
                    console.log('login succeed')
                    //AuthenticationService.setupAxiosInterceptors(this.state.username, this.state.password)
                    AuthenticationService.registerSuccessfulLogin(this.state.username, this.state.password)
                    this.props.history.push({
                        pathname: '/welcome',
                        state:{
                            username: this.state.username
                        }
                    })
                } else {
                    console.log('login failed')
                    this.setState({hasLoginFailed : true})
                }

            }).catch( () =>{
                console.log('login fail')
                this.setState({hasLoginFailed : true})
            })

    }

    registerClicked(){
        this.props.history.push('/register')
        console.log('register clicked')
    }
}

export default Login