import axios from "axios";
import { FMS_API_URL_PRODUCT_LIST } from '../Constants';

class SSUtils{
    getUUID() {
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
          s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[8] = s[13] = s[18] = s[23] = "-";    //"" 引号里面可以加任意字符，代表拼接的意思，如果不加就是 纯32位支付
    
        var uuid = s.join("");
        return uuid;
    }

    loadProductData() {
        //if (sessionStorage.getItem('PRODUCT_LIST') === null) {
          axios.get(FMS_API_URL_PRODUCT_LIST)
          .then(response => {
            let productStaticInfo = response.data
            let productTxtArray = productStaticInfo.split('\r\n')
            let productObjArray = []
      
            for(var pt of productTxtArray){
              let subArray = pt.split('\t')
      
              let pkid = subArray[0]
              let name = subArray[1]
              let price = subArray[2]
      
              if(pkid){
                productObjArray.push({pkid, name, price})
              }
            }
      
            console.log('TXT resource retrieved.')
      
           sessionStorage.setItem('PRODUCT_LIST',JSON.stringify(productObjArray)) 
          });
        //}
      }
      
    printDate(d) {
        var dateStr = this.padStr(d.getFullYear()) +
                      this.padStr(1 + d.getMonth()) +
                      this.padStr(d.getDate()) +
                      this.padStr(d.getHours()) +
                      this.padStr(d.getMinutes()) +
                      this.padStr(d.getSeconds());
        return dateStr
    }
    
    padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }
}

export default new SSUtils()