import React, { Component } from 'react'
import { FormControl, RadioGroup, FormControlLabel, Radio, TableHead } from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import * as yup from 'yup';
import SSUtils from '../api/SSUtils.js';
import OrderDataService from '../api/OrderDataService'
import MomentUtils from "@date-io/moment";
import "moment/locale/zh-cn";
import AlertConfirmOrderSubmit from './AlertConfirmOrderSubmit'
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';




class OrderEdit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: props.location.orderid,
            customer_id: 0,
            order_time:0,
            is_synced:0,
            total_amount: 0,
            note:'',
            org_city:'',
            org_province:'',
            org_full_name:'',
            org_address:'',
            org_bank_name:'',
            org_bank_account:'',
            org_TAX_id:'',
            org_telephone:'',
            org_email:'',
            need_invoice: '',
            postinfo_receiver_name:'',
            postinfo_receiver_org:'',
            postinfo_receiver_address:'',
            postinfo_telephone:'',
            postinfo_postcode:'',
            payer:'',
            pay_date:'',
            order_lineitems:[],
            order_status:'草稿'
        }
    }

    componentDidMount() {

        OrderDataService.retrieveOrder(this.state.id)
        .then(response => 
            this.setState({
                customer_id:        response.data.customer_id,
                order_time:         response.data.order_time,
                is_synced:          response.data.is_synced,
                total_amount:       response.data.total_amount,
                note:               response.data.note,
                org_city:           response.data.org_city,
                org_province:       response.data.org_province,
                org_full_name:      response.data.org_full_name,
                org_address:        response.data.org_address,
                org_bank_name:      response.data.org_bank_name,
                org_bank_account:   response.data.org_bank_account,
                org_TAX_id:         response.data.org_TAX_id,
                org_telephone:      response.data.org_telephone,
                org_email:          response.data.org_email,
                need_invoice:    response.data.need_invoice,
                postinfo_receiver_name:     response.data.postinfo_receiver_name,
                postinfo_receiver_org:      response.data.postinfo_receiver_org,
                postinfo_receiver_address:  response.data.postinfo_receiver_address,
                postinfo_telephone: response.data.postinfo_telephone,
                postinfo_postcode:  response.data.postinfo_postcode,
                payer:              response.data.payer,
                pay_date:           response.data.pay_date,
                order_lineitems:    response.data.order_lineitems
            })
        )
    }

    render() {
        let productListStr = sessionStorage.getItem('PRODUCT_LIST')
        let productList = JSON.parse(productListStr)

        console.log('product list is:' + JSON.stringify(productList))

        let clickedMenuItemIndex = -1
        let orderStatus = "草稿"
        let totalOrderAmount = this.state.total_amount
        let submissionTime = null

        function validateTAXid(value) {
            let error;
            if (!value) {
                error = '税号不能为空';
            } else if (!/^[A-Z0-9]{15,20}$/.test(value)){
                error = '税号格式不正确（由数字或字母组成，必须为15-20位）'
            }
            return error;
        }

        const validationSchema = yup.object({
            org_TAX_id: yup
              .string('请输入税号(18位)')
              .required('税号不能为空')
              .matches(
                /^[A-Z0-9]{15,20}$/,
                "税号格式不正确（由数字或字母组成，必须为15-20位）"
              ),
            org_full_name: yup
                .string('请输入贵单位名称的全称')
                .required('单位全称不能为空'),
            org_email: yup
                .string('请输入您的互联网邮箱')
                .email()
                .required('互联网邮箱不能为空'),
            org_telephone: yup
                .string('单位联系电话')
                .max(20),
            postinfo_receiver_address: yup
                .string('请输入收货地址')
                .required('收货地址不能为空'),
            postinfo_receiver_org: yup
                .string('请输入收货单位名称')
                .required('收货单位名称不能为空'),
            postinfo_receiver_name: yup
                .string('请输入收货人姓名')
                .required('收货人姓名不能为空'),
            postinfo_telephone: yup
                .string('请输入联系电话')
                .required('联系电话不能为空'),
            postinfo_postcode: yup
                .string('请输入邮编')
                .matches(
                    /^[0-9]{6,6}$/,
                    "邮编必须是6位数字 "
                )
                .required('邮编不能为空'),
            payer: yup
                .string('请输入付款人姓名')
                .required('付款人不能为空'),
            pay_date: yup
                .date('请输入汇款日期')
                .required('汇款日期不能为空')
                .nullable(),
            lineitems: yup
                .array().min(1),
            total_amount: yup
                .number().required().positive()
          });
        
        return (
            <>
                <div>
                    <h1>订单信息（编辑）</h1>
                    <br/>
                    <Formik
                    enableReinitialize
                    validationSchema={validationSchema}
                    initialValues={{
                        customer_id:        this.state.customer_id,
                        order_time:         this.state.order_time,
                        is_synced:          this.state.is_synced,
                        total_amount:       this.state.total_amount,
                        note:               this.state.note,
                        org_city:           this.state.org_city,
                        org_province:       this.state.org_province,
                        org_full_name:      this.state.org_full_name,
                        org_address:        this.state.org_address,
                        org_bank_name:      this.state.org_bank_name,
                        org_bank_account:   this.state.org_bank_account,
                        org_TAX_id:         this.state.org_TAX_id,
                        org_telephone:      this.state.org_telephone,
                        org_email:          this.state.org_email,
                        need_invoice:       this.state.need_invoice,
                        postinfo_receiver_name: this.state.postinfo_receiver_name,
                        postinfo_receiver_org:  this.state.postinfo_receiver_org,
                        postinfo_receiver_address:  this.state.postinfo_receiver_address,
                        postinfo_telephone: this.state.postinfo_telephone,
                        postinfo_postcode:  this.state.postinfo_postcode,
                        payer:              this.state.payer,
                        pay_date:           this.state.pay_date,
                        lineitems:          this.state.order_lineitems
                    }}

                    onSubmit={values => {

                        let order = {
                            id:                 this.state.id,
                            customer_id:        values.customer_id,
                            order_time:         values.order_time,
                            is_synced:          values.is_synced,
                            total_amount:       values.total_amount,
                            note:               values.note,
                            org_city:           values.org_city,
                            org_province:       values.org_province,
                            org_full_name:      values.org_full_name,
                            org_address:        values.org_address,
                            org_bank_name:      values.org_bank_name,
                            org_bank_account:   values.org_bank_account,
                            org_TAX_id:         values.org_TAX_id,
                            org_telephone:      values.org_telephone,
                            org_email:          values.org_email,
                            need_invoice:    values.need_invoice,
                            postinfo_receiver_name:     values.postinfo_receiver_name,
                            postinfo_receiver_org:      values.postinfo_receiver_org,
                            postinfo_receiver_address:  values.postinfo_receiver_address,
                            postinfo_telephone: values.postinfo_telephone,
                            postinfo_postcode:  values.postinfo_postcode,
                            payer:              values.payer,
                            pay_date:           values.pay_date,
                            order_lineitems:    values.lineitems,
                            status:             orderStatus,
                            zm_submission_time: submissionTime
                        }

                        
                        OrderDataService.updateOrder(order)
                            .then((response) => this.props.history.push({
                                pathname: '/order/view/',
                                state:{
                                    orderid: response.data
                                }
                            } ))
                        
                    }}

                    >
                    {(props) => (
                        <Form >
                            <h3>发票信息</h3>
                            
                            

                            <div className="container">
                            <Table className="table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <FormControl component="fieldset" >
                                                <RadioGroup row name="need_invoice" value={props.values.need_invoice} onChange={(event)=>{props.setFieldValue("need_invoice", event.target.value)}}>
                                                    <span className="Radio-group-legend">是否开票:&nbsp;&nbsp;&nbsp;</span>
                                                    <FormControlLabel value="是" control={<Radio />} label="是" />
                                                    <FormControlLabel value="否" control={<Radio />} label="否" />
                                                </RadioGroup>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="field" style={{width: "50%"}}>
                                            <TextField
                                                id="org_full_name"
                                                name="org_full_name"
                                                label="单位全称 *必填*"
                                                value={props.values.org_full_name}
                                                onChange={props.handleChange}
                                                error={props.touched.org_full_name && Boolean(props.errors.org_full_name)}
                                                helperText={props.touched.org_full_name && props.errors.org_full_name}
                                                style={{width: "100%"}}
                                            />
                                        </TableCell>
                                        <TableCell className="field" style={{width: "50%"}}>
                                            <TextField
                                                id="org_TAX_id"
                                                name="org_TAX_id"
                                                label="购货单位纳税人识别号 *必填*"
                                                value={props.values.org_TAX_id}
                                                onChange={props.handleChange}
                                                error={props.touched.org_TAX_id && Boolean(props.errors.org_TAX_id)}
                                                helperText={props.touched.org_TAX_id && props.errors.org_TAX_id}
                                                style={{width: "100%"}}
                                                validate={validateTAXid}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="field" style={{width: "50%"}}>
                                            <TextField
                                                id="org_address"
                                                name="org_address"
                                                label="购货单位地址"
                                                value={props.values.org_address}
                                                onChange={props.handleChange}
                                                error={props.touched.org_address && Boolean(props.errors.org_address)}
                                                helperText={props.touched.org_address && props.errors.org_address}
                                                style={{width: "100%"}}
                                            />
                                        </TableCell>
                                        <TableCell className="field" style={{width: "50%"}}>
                                            <TextField
                                                id="org_telephone"
                                                name="org_telephone"
                                                label="购货单位电话"
                                                value={props.values.org_telephone}
                                                onChange={props.handleChange}
                                                error={props.touched.org_telephone && Boolean(props.errors.org_telephone)}
                                                helperText={props.touched.org_telephone && props.errors.org_telephone}
                                                style={{width: "100%"}}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="field" style={{width: "50%"}}>
                                            <TextField
                                                id="org_bank_name"
                                                name="org_bank_name"
                                                label="购货单位开户行"
                                                value={props.values.org_bank_name}
                                                onChange={props.handleChange}
                                                error={props.touched.org_bank_name && Boolean(props.errors.org_bank_name)}
                                                helperText={props.touched.org_bank_name && props.errors.org_bank_name}
                                                style={{width: "100%"}}
                                            />
                                        </TableCell>
                                        <TableCell className="field" style={{width: "50%"}}>
                                            <TextField
                                                id="org_bank_account"
                                                name="org_bank_account"
                                                label="开户行账号"
                                                value={props.values.org_bank_account}
                                                onChange={props.handleChange}
                                                error={props.touched.org_bank_account && Boolean(props.errors.org_bank_account)}
                                                helperText={props.touched.org_bank_account && props.errors.org_bank_account}
                                                style={{width: "100%"}}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="field" style={{width: "50%"}}>
                                            <TextField
                                                id="org_email"
                                                name="org_email"
                                                label="互联网邮箱 *必填*"
                                                value={props.values.org_email}
                                                onChange={props.handleChange}
                                                error={props.touched.org_email && Boolean(props.errors.org_email)}
                                                helperText={props.touched.org_email && props.errors.org_email}
                                                style={{width: "100%"}}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <br/>
                            </div>

                            <h3>邮寄信息</h3>
                            <div className="container">
                                <Table className="table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="field" style={{width: "25%"}}>
                                                <TextField
                                                    id="org_province"
                                                    name="org_province"
                                                    label="省 *必填*"
                                                    value={props.values.org_province}
                                                    onChange={props.handleChange}
                                                    error={props.touched.org_province && Boolean(props.errors.org_province)}
                                                    helperText={props.touched.org_province && props.errors.org_province}
                                                    style={{width: "100%"}}
                                                />
                                            </TableCell>
                                            <TableCell className="field" style={{width: "25%"}}>
                                                <TextField
                                                    id="org_city"
                                                    name="org_city"
                                                    label="市 *必填*"
                                                    value={props.values.org_city}
                                                    onChange={props.handleChange}
                                                    error={props.touched.org_city && Boolean(props.errors.org_city)}
                                                    helperText={props.touched.org_city && props.errors.org_city}
                                                    style={{width: "100%"}}
                                                />
                                            </TableCell>
                                            <TableCell className="field" style={{width: "50%"}}>
                                                <TextField
                                                    id="postinfo_receiver_address"
                                                    name="postinfo_receiver_address"
                                                    label="收货单位地址 *必填*"
                                                    value={props.values.postinfo_receiver_address}
                                                    onChange={props.handleChange}
                                                    error={props.touched.postinfo_receiver_address && Boolean(props.errors.postinfo_receiver_address)}
                                                    helperText={props.touched.postinfo_receiver_address && props.errors.postinfo_receiver_address}
                                                    style={{width: "100%"}}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="field" colSpan={2}>
                                                <TextField
                                                    id="postinfo_receiver_org"
                                                    name="postinfo_receiver_org"
                                                    label="收货单位 *必填*"
                                                    value={props.values.postinfo_receiver_org}
                                                    onChange={props.handleChange}
                                                    error={props.touched.postinfo_receiver_org && Boolean(props.errors.postinfo_receiver_org)}
                                                    helperText={props.touched.postinfo_receiver_org && props.errors.postinfo_receiver_org}
                                                    style={{width: "100%"}}
                                                />
                                            </TableCell>
                                            <TableCell className="field" style={{width: "50%"}}>
                                                <TextField
                                                    id="postinfo_receiver_name"
                                                    name="postinfo_receiver_name"
                                                    label="收货人 *必填*"
                                                    value={props.values.postinfo_receiver_name}
                                                    onChange={props.handleChange}
                                                    error={props.touched.postinfo_receiver_name && Boolean(props.errors.postinfo_receiver_name)}
                                                    helperText={props.touched.postinfo_receiver_name && props.errors.postinfo_receiver_name}
                                                    style={{width: "100%"}}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="field" colSpan={2}>
                                                <TextField
                                                    id="postinfo_telephone"
                                                    name="postinfo_telephone"
                                                    label="联系电话 *必填*"
                                                    value={props.values.postinfo_telephone}
                                                    onChange={props.handleChange}
                                                    error={props.touched.postinfo_telephone && Boolean(props.errors.postinfo_telephone)}
                                                    helperText={props.touched.postinfo_telephone && props.errors.postinfo_telephone}
                                                    style={{width: "100%"}}
                                                />
                                            </TableCell>
                                            <TableCell className="field" style={{width: "50%"}}>
                                                <TextField
                                                    id="postinfo_postcode"
                                                    name="postinfo_postcode"
                                                    label="邮编 *必填*"
                                                    value={props.values.postinfo_postcode}
                                                    onChange={props.handleChange}
                                                    error={props.touched.postinfo_postcode && Boolean(props.errors.postinfo_postcode)}
                                                    helperText={props.touched.postinfo_postcode && props.errors.postinfo_postcode}
                                                    style={{width: "100%"}}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="field" colSpan={2}>
                                            <div>如果需要邮寄至多个地址，请为每个地址填写一份订单；</div>
                                            </TableCell>
                                            <TableCell className="field" style={{width: "50%"}}>
                                            <div>举例：若要将杂志邮寄至10个收件地址，则需要填写10份订单；如果需要统一开具发票，请再填写一份“汇总订单”（共需填写11份订单）；如仍有疑问请拨打电话(010) 66261248</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <br/>
                            </div>
                            

                            <h3>购货信息</h3>
                            <FieldArray name="lineitems">
                                {({ insert, remove, push }) => (
                                <div className="container">
                                    <Table className="table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{width: "33%"}}>
                                                    <IconButton
                                                    type="button"
                                                    variant="outlined"
                                                    className="secondary"
                                                    onClick={() => {
                                                        SSUtils.loadProductData()
                                                        SSUtils.loadProductData()

                                                        push({ 
                                                            id: SSUtils.getUUID(),
                                                            order_id: this.state.id,
                                                            product: "请选择货物", 
                                                            price: "",
                                                            quantity: "",
                                                            note: "",
                                                            amount: "",
                                                            zm_creation_time: new Date()
                                                        })
                                                    }}
                                                    >
                                                    <AddIcon></AddIcon>
                                                    </IconButton>
                                                    货物名称</TableCell>
                                                <TableCell style={{width: "10%"}}>单价</TableCell>
                                                <TableCell style={{width: "10%"}}>数量</TableCell>
                                                <TableCell style={{width: "10%"}}>小计</TableCell>
                                                <TableCell style={{width: "33%"}}>备注</TableCell>
                                                <TableCell style={{width: "4%"}}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {props.values.lineitems.length > 0 &&
                                                props.values.lineitems.sort((a,b)=>(Date.parse(a.zm_creation_time) - Date.parse(b.zm_creation_time)))
                                                .map((lineitem, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell >
                                                            {/*<TextField
                                                             name={`lineitems.${index}.product`}
                                                             value={props.values.lineitems[index].product || ''}
                                                             onChange={props.handleChange}
                                                             style={{width: "100%"}}
                                                            />*/}
                                                            <FormControl style={{width: "100%"}}>
                                                                <Select
                                                                name={`lineitems.${index}.product`}
                                                                labelId="label-lineitem-product"
                                                                value={props.values.lineitems[index].product || ''}
                                                                onChange={(e) => {
                                                                    props.setFieldValue(`lineitems.${index}.product`, e.target.value)
                                                                    props.setFieldValue(`lineitems.${index}.price`, productList[clickedMenuItemIndex].price)
                                                                    props.setFieldValue(`lineitems.${index}.amount`, 
                                                                    productList[clickedMenuItemIndex].price * props.values.lineitems[index].quantity)

                                                                    totalOrderAmount = 0
                                                                    for(var i = 0; i < props.values.lineitems.length; i++){
                                                                        console.log(props.values.lineitems[i].product + ' ' + props.values.lineitems[i].quantity + ' ' + props.values.lineitems[i].price+ ' ' + props.values.lineitems[i].amount)
                                                                        if(i === index){
                                                                            totalOrderAmount += props.values.lineitems[i].quantity * productList[clickedMenuItemIndex].price
                                                                        } else{
                                                                            totalOrderAmount += props.values.lineitems[i].amount
                                                                        }
                                                                    }
                                                                    props.setFieldValue(`total_amount`, totalOrderAmount)
                                                                    console.log(totalOrderAmount + ' is the sum')
                                                                }}
                                                                >
                                                                    {productList.length > 0 &&
                                                                        productList.map((p,i) => (
                                                                            <MenuItem key={i} value={p.name + ' ￥' + p.price} onClick={()=>{clickedMenuItemIndex = i}}>{p.name + ' ￥' + p.price}</MenuItem>
                                                                        )
                                                                        )
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            {props.values.lineitems[index].price || ''}
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                             name={`lineitems.${index}.quantity`}
                                                             value={props.values.lineitems[index].quantity || ''}
                                                             onChange={(e) => {
                                                                props.setFieldValue(`lineitems.${index}.quantity`, e.target.value)
                                                                props.setFieldValue(`lineitems.${index}.amount`, 
                                                                                    props.values.lineitems[index].price * e.target.value)
                                                                totalOrderAmount = 0
                                                                for(var i = 0; i < props.values.lineitems.length; i++){
                                                                    console.log(props.values.lineitems[i].product + ' ' + props.values.lineitems[i].quantity + ' ' + props.values.lineitems[i].price+ ' ' + props.values.lineitems[i].amount)
                                                                    if(i === index){
                                                                        totalOrderAmount += e.target.value * props.values.lineitems[index].price
                                                                    } else{
                                                                        totalOrderAmount += props.values.lineitems[i].amount
                                                                    }
                                                                }
                                                                props.setFieldValue(`total_amount`, totalOrderAmount)
                                                                console.log(totalOrderAmount + ' is the sum')
                                                            }}
                                                             style={{width: "100%"}}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            {props.values.lineitems[index].amount || ''}

                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                             name={`lineitems.${index}.note`}
                                                             value={props.values.lineitems[index].note || ''}
                                                             onChange={props.handleChange}
                                                             style={{width: "100%"}}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <IconButton
                                                                color="primary"
                                                                onClick={() => {
                                                                    remove(index)
                                                                    props.values.lineitems.splice(index, 1)
                                                                    totalOrderAmount = 0
                                                                    for(var i = 0; i < props.values.lineitems.length; i++){
                                                                        totalOrderAmount += props.values.lineitems[i].amount
                                                                    }
                                                                    props.setFieldValue(`total_amount`, totalOrderAmount)
                                                                }}
                                                            >
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                           
                                        ))}
                                        </TableBody>
                                    </Table>
                                    
                                    订单总金额: {totalOrderAmount} 元
                                    <br/>
                                    <br/>
                                    {
                                        props.values.total_amount <= 0 &&
                                        <span className="Must-fill-indicator">
                                            请填写至少一项购货信息，否则订单将不能被保存或提交。
                                        </span>
                                    }
                                    <br/><br/><br/>
                                </div>
                                )}
                            </FieldArray>

                            <h3>付款信息</h3>

                            <div className="container">
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="field" style={{width: "25%"}}>
                                                <TextField
                                                    id="payer"
                                                    name="payer"
                                                    label="付款人"
                                                    value={props.values.payer}
                                                    onChange={props.handleChange}
                                                    error={props.touched.payer && Boolean(props.errors.payer)}
                                                    helperText={props.touched.payer && props.errors.payer}
                                                    style={{width: "100%"}}
                                                />
                                            </TableCell>
                                            
                                            <TableCell style={{width: "25%"}}>
                                                <MuiPickersUtilsProvider locale="zh-cn" utils={MomentUtils}>
                                                    <KeyboardDatePicker
                                                    InputLabelProps={{ shrink: true }}  
                                                    disableToolbar
                                                    variant="inline"
                                                    format="YYYY-MM-DD"
                                                    margin="normal"
                                                    id="date-picker-inline"
                                                    label="付款日期"
                                                    value={props.values.pay_date || null}
                                                    onChange={value => {
                                                        console.log(value)
                                                        props.setFieldValue("pay_date", value);
                                                      }}
                                                    error={props.touched.pay_date && Boolean(props.errors.pay_date)}
                                                    style={{width: "100%"}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </TableCell>
                                            <TableCell className="field" style={{width: "50%"}}>
                                                <TextField
                                                    id="note"
                                                    name="note"
                                                    label="备注"
                                                    value={props.values.note}
                                                    onChange={props.handleChange}
                                                    error={props.touched.note && Boolean(props.errors.note)}
                                                    helperText={props.touched.note && props.errors.note}
                                                    style={{width: "100%"}}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{width: "25%"}}>
                                                <div>付款人栏位内请填写实际的付款人（单位名称或个人名字）</div>
                                            </TableCell>
                                            <TableCell style={{width: "25%"}}>
                                                <div>如需先开发票，则付款日期栏位可填写预估的付款日期，晚一些也没关系</div>
                                            </TableCell>
                                            <TableCell style={{width: "50%"}}>
                                                <div>如需先开发票，请在备注栏内填写“先开发票”</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                            <br/>

                            <div>
                                <Button type="submit" variant="outlined" >存为草稿</Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <AlertConfirmOrderSubmit handleContinue={()=>{
                                        orderStatus="已提交"
                                        submissionTime = new Date()
                                        props.submitForm()
                                    }}/>
                            </div>
                            

                            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                            
                        </Form>
                    )}
                    </Formik>

                </div>
            </>
        )
    }
}

export default OrderEdit





