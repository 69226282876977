import axios from 'axios'
import Base64 from './Base64'
import OrganizationDataService from './OrganizationDataService'
import SSUtils from '../api/SSUtils'
import { JPA_API_URL } from '../Constants';


class AuthenticationService {

    executeBasicAuthenticationService(username, password) {
        console.log('executeBasicAuthenticationService executed')
        console.log('executeBasicAuthenticationService basic-authtoken:' + this.createBasicAuthToken(username,password))
        return axios.get(`${JPA_API_URL}/basicauth`, 
            {headers: {authorization: this.createBasicAuthToken(username,password)}})
    }

    createBasicAuthToken(username,password) {
       // return 'Basic ' + window.btoa(unescape(encodeURIComponent(username + ":" + password)))
       return 'Basic ' + Base64.encode(username + ":" + password)
    }


    logout() {
        console.log('logout executed')
        sessionStorage.clear();
    }

    registerSuccessfulLogin(username,password){
        //let basicAuthHeader = 'Basic ' +  window.btoa(username + ":" + password)
        console.log('AuthenticationService registerSuccessLogin: call start' + username + ' : ' + password)

        SSUtils.loadProductData()

        OrganizationDataService.getOrganizationObjectByFullname(username, password)
        .then(response => {
            sessionStorage.setItem('authenticatedUser',             response.data.fullname)
            sessionStorage.setItem('authenticatedUserId',           response.data.id)
            sessionStorage.setItem('authenticatedUserProvince',     response.data.province)
            sessionStorage.setItem('authenticatedUserCity',         response.data.city)
            sessionStorage.setItem('authenticatedUserAddress',      response.data.address)
            sessionStorage.setItem('authenticatedUserPostcode',     response.data.postcode)
            sessionStorage.setItem('authenticatedUserContactPerson',response.data.contact_person)
            sessionStorage.setItem('authenticatedUserContactPhone', response.data.contact_phone)
            sessionStorage.setItem('authenticatedUserEmail',        response.data.email)
            sessionStorage.setItem('authenticatedUserPassword',     response.data.password)
            sessionStorage.setItem('authenticatedUserTaxID',        response.data.tax_id)
            sessionStorage.setItem('authenticatedUserBankName',     response.data.bank_name)
            sessionStorage.setItem('authenticatedUserBankAccount',  response.data.bank_account)

            console.log('AuthenticationService getOrganizationObjectByFullname done :' + response.data.fullname)
        })

        console.log('AuthenticationService registerSuccessfulLogin: fullname :' + sessionStorage.getItem('authenticatedUser'))
        console.log('AuthenticationService registerSuccessfulLogin: call end, basicAuthToken is ' + this.createBasicAuthToken(username,password))

    }

    setupAxiosInterceptors(username, password) {
        let basicAuthHeader = this.createBasicAuthToken(username, password)
        console.log('AuthenticationService setupAxiosInterceptors start: ' + username + ' : ' + password)
        console.log('AuthenticationService setupAxiosInterceptors new token: ' + basicAuthHeader)

        axios.interceptors.request.use(
            (config) => {
                config.headers.authorization = basicAuthHeader

                return config
            }
        )

        console.log('AuthenticationService setupAxiosInterceptors done')
    }

    isUserLoggedIn() {
        console.log('AuthenticationService isUserLoggedIn executed')

        let user = sessionStorage.getItem('authenticatedUser')
        if (user === null) {
            console.log('isUserLoggedIn: user is null')
            return false
        }

        console.log('isUserLoggedIn: user is not null')
        return true
    }
}

export default new AuthenticationService()