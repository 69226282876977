import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

const DialogWebsiteInstruction = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }


  const handleYes = () => {
      setOpen(false)
    }


  return (
    <>
        
        <button className="btn btn-success"
            onClick={handleClickOpen}
        >
          操作说明
        </button>
        <Dialog
            open={open}
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown={true}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  handleClose();
                } 
            }}        >
            <DialogTitle id="wxpay-qrcode-dialog-title">
                {"如何使用本网站订阅《公安研究》？"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                1、初次使用本系统订阅《公安研究》时，需要使用您的单位名称在系统里进行注册（回到网站首页，点击“注册”按钮，根据网页提示填写您的单位信息即可）。
                <br/><br/>
                2、注册完毕后登录进入系统，点击“新建订单”后根据网页提示填写订单信息，提交订单。
                <br/><br/>
                3、如果您单位需要先开发票然后再付款，请在填写订单时备注”先开发票“，《公安研究》的发票将以电子发票的形式发送到您的互联网邮箱。
                <br/><br/>
                4、提交订单后，您可以通过扫描订单的微信支付二维码进行付款，或者通过银行转账的方式进行汇款（汇款信息在您登录后的页面内可以找到），在收到您单位的汇款后，会有中警协的工作人员给您开具电子发票（发送到您的互联网邮箱）。
                <br/><br/>
                5、如有其他问题，请致电 (010) 66261248 进行咨询。
              </DialogContentText>
            </DialogContent>
            
            <DialogActions>
                <Button onClick={handleYes} autoFocus>
                    返回
                </Button>
            </DialogActions>
        </Dialog>
    </>
  );
}


export default DialogWebsiteInstruction