import axios from 'axios'
import { JPA_API_URL } from '../Constants';
import Base64 from './Base64'

class OrganizationDataService {
    createBasicAuthToken(username,password) {
        // return 'Basic ' + window.btoa(unescape(encodeURIComponent(username + ":" + password)))
        return 'Basic ' + Base64.encode(username + ":" + password)
     }

    retrieveOrganization(id) {
        console.log('OrganizationDataService executed: retrieve org by id')
        console.log('OrganizationDataService-retrieveOrganization: org name is ' + sessionStorage.getItem('authenticatedUser'))
        
        let token = this.createBasicAuthToken(
            sessionStorage.getItem('authenticatedUser'),
            sessionStorage.getItem('authenticatedUserPassword'),
        )
        
        console.log(token)

        

        return axios.get(`${JPA_API_URL}/organization/id/${id}`, 
            {headers: {authorization: token}});
    }

    updateOrganization(org) {
        console.log('OrganizationDataService executed: update org')
        
        return axios.put(`${JPA_API_URL}/organization/`, org, 
            {headers: {authorization: this.createBasicAuthToken(
                sessionStorage.getItem('authenticatedUser'),
                sessionStorage.getItem('authenticatedUserPassword'),
            )}});
    }

    createOrganization(org) {
        console.log('OrganizationDataService executed: create org')
        return axios.post(`${JPA_API_URL}/register`, org);
    }

    getOrganizationIdByFullname(orgname, password){
        console.log('OrganizationDataService executed: get org id by fullname')

        return axios.get(`${JPA_API_URL}/organization/name/${orgname}`, 
            {headers: {authorization: this.createBasicAuthToken(orgname,password)}})
    }

    getOrganizationObjectByFullname(orgname, password){
        console.log('OrganizationDataService executed: get org object by fullname')

        return axios.get(`${JPA_API_URL}/organization/object/${orgname}`, 
            {headers: {authorization: this.createBasicAuthToken(orgname,password)}})
    }
}

export default new OrganizationDataService()