import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Paper from '@mui/material/Paper';


function Welcome (props){

    const [orgname, setOrgname] = useState(sessionStorage.getItem('authenticatedUser'))
    const [orgid, setOrgid] = useState(sessionStorage.getItem("authenticatedUserId"))

    useEffect(
        ()=>{
            setTimeout(() => {
                setOrgname(sessionStorage.getItem('authenticatedUser'))
                setOrgid(sessionStorage.getItem("authenticatedUserId"))

            }, 333);
        },[orgname,orgid]
    )



    console.log('welcome : username ' + orgname)
    console.log('welcome : orgid ' + orgid)

    return (
        <>
            <br/>
            <h1>欢迎您!  {orgname} 的朋友。</h1>
            <br/>
            <div className="order_page">
                    查看历史订单，请点击：<Link to="/orders">“订单列表”</Link>
                    <br/><br/>
                    新建一个订单，请点击：<Link to='/order/new'>“新建订单”</Link>
                    <br/><br/>
                    查看单位信息，请点击：<Link to={{
                        pathname: "/organization/view",
                        orgid: orgid
                        }}>“单位信息”</Link>


            </div>
            <br/><br/>
            <div className="container" width="60%">
                <b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;《公安研究》(月刊) 订阅办法:</b>&nbsp;<br/>
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1）通过银行订阅   -   请汇往:   工商银行北京王府井金街支行   
                (开户名:中国警察协会，账号: 0200235129000007963) ，请务必在汇款备注中注明调阅单位的简称和联系电话等信息。
                <br/>
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2）通过邮局订阅   -   请填写:   北京市东长安街14号(地址)，<span id="gayj_red_text">《公安研究》编辑部(收件人)</span>，100741 (邮编)。
                <br/>
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3）通过本网站订阅 - 提交订单后，在订单详情下方或者订单列表右侧会有微信支付按钮，点击后使用微信扫描二维码即可付款完成订阅。
                <br/>
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;订户通过银行或邮局完成订阅后，请登陆《公安研究》订阅管理系统网站(www.gayj.org.cn)注册单位信息并提交订购单，或按要求填好订购单并传真至编辑部。
                <br/>               
            </div>
            
            <br/><br/>
            <div className="container" width="60%">
                <b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;《公安研究》编辑部：</b>&nbsp;<br/>
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;地址：北京市东长安街14号
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;邮编：100741
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;开户银行：工行北京国家文化与金融合作示范区金街支行
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;开户名：中国警察协会
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;账号：0200235129000007963
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;跨行汇款行号：102100000072
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;同城交换行行号：1221
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;查询电话：(010) 66261248
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;互联网邮箱：gongan@policingstudes.org
                <br/><br/>
                <br/><h4>订阅咨询(传真)电话: 010-66261248欢迎投稿、订阅《公安研究》</h4>
            </div>     


            <br/><br/><br/><br/>

        </>
    )
}

export default Welcome