import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import OrderDataService from '../api/OrderDataService'
import AlertConfirmOrderDelete from './AlertConfirmOrderDelete'
import DialogWXPayQRCode from './DialogWXPayQRCode'



function OrderList (props){

    const [orders, setOrders] = useState([])

    useEffect(
        ()=>{
            console.log('OrderList useEffect start')
            let orgId = sessionStorage.getItem('authenticatedUserId')

            OrderDataService.retrieveAllOrders(orgId)
                .then(
                    response => {
                        setOrders(response.data)
                        console.log(response.data)
                    }
                )
        },[]
    )

    let i = 1

    return (
        <div>
            <h1>订单列表</h1>

            <div className="container">
                <table className="table">
                    <thead>
                        <tr>
                            <th>序号</th>
                            <th>订单日期</th>
                            <th>订货内容</th>
                            <th>总金额</th>
                            <th>收货地址</th>
                            <th>收货人</th>
                            <th>联系电话</th>
                            <th>备注</th>
                            <th>订单状态</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            (orders.length > 0) &&
                                orders.sort((a,b)=>(Date.parse(a.zm_creation_time) - Date.parse(b.zm_creation_time))).map(
                                    order => 
                                        <tr key={order.id}>
                                            <td>{i++}</td>
                                            <td>{moment(order.order_time).format('YYYY-MM-DD')}</td>
                                            <td>{order.lineItems}</td>
                                            <td>{order.total_amount}</td>
                                            <td>{order.postinfo_receiver_address}</td>
                                            <td>{order.postinfo_receiver_name}</td>
                                            <td>{order.postinfo_telephone}</td>
                                            <td>{order.note}</td>
                                            <td>{order.status}</td>
                                            <td>{<>
                                                <Link to={{
                                                    pathname: "/order/view",
                                                    state: {
                                                        orderid: order.id
                                                        }
                                                    }}><button>查看</button>
                                                </Link>
                                                &nbsp; {order.orderNo}
                                                {(order.status !== "已提交") && 
                                                    <AlertConfirmOrderDelete handleContinue={()=>{
                                                        OrderDataService.deleteOrderById(order.id).then(response => {
                                                            let orgId = sessionStorage.getItem('authenticatedUserId')

                                                            OrderDataService.retrieveAllOrders(orgId)
                                                                .then(
                                                                    response => {
                                                                        setOrders(response.data)
                                                                        console.log(response.data)
                                                                    }
                                                                )
                                                        })
                                                        //window.location.reload(false)
                                                    }}/>
                                                }
                                                {(order.status === "已提交") && (order.isScanPayable === 1) && (order.wechat_pay_status !== "已支付") &&
                                                    <DialogWXPayQRCode 
                                                        handleContinue={()=>{
                                                        }}
                                                        orderNo = {order.order_no}                                                    
                                                    />
                                                }
                                                {(order.status === "已提交") && (order.isScanPayable === 1) && (order.wechat_pay_status === "已支付") &&
                                                    "(已微信支付)"
                                                }
                                                </>
                                                }
                                            </td>
                                        </tr>

                                )
                            
                        }
                    </tbody>
                </table>
                <hr></hr>
                <div>
                    <Link to='/order/new'><button>新建订单</button></Link>
                </div>
            </div>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        </div>
    )
}

export default OrderList





