import React, {useState, useEffect} from 'react'
import OrganizationDataService from '../api/OrganizationDataService'
import { Link } from 'react-router-dom'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

function OrganizationView (props){

    let orgid = sessionStorage.getItem('authenticatedUserId') || props.location.orgid || props.location.state.orgid
    console.log('OrganizationView constructor : orgid is ' + orgid)

    const [orginfo, setOrginfo] = useState({})

    useEffect(
        ()=>{
            setTimeout(() => {
                OrganizationDataService.retrieveOrganization(orgid)
                .then(response => setOrginfo(response.data))
            }, 33);

        },[orgid]
    )

    return (
        <div>
            <h1 className="text-success">单位信息</h1>
            <div className="container">
                <Table className="table">
                    <TableBody>
                        <TableRow>
                            <TableCell className="label" style={{ width: 210}}>ID</TableCell>
                            <TableCell className="field" >{orgid}</TableCell>
                            <TableCell className="label" style={{ width: 66}}>省</TableCell>
                            <TableCell className="field" >{orginfo.province}</TableCell>
                            <TableCell className="label" style={{ width: 66}}>市</TableCell>
                            <TableCell className="field" >{orginfo.city}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="label" style={{ width: 210}}>单位全称</TableCell>
                            <TableCell className="field" colSpan={5}>{orginfo.fullname}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="label" style={{ width: 210}}>单位税号</TableCell>
                            <TableCell className="field" colSpan={5}>{orginfo.tax_id}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="label" style={{ width: 210}}>开户行</TableCell>
                            <TableCell className="field" colSpan={5}>{orginfo.bank_name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="label" style={{ width: 210}}>开户行账号</TableCell>
                            <TableCell className="field" colSpan={5}>{orginfo.bank_account}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="label" style={{ width: 210}}>联系地址</TableCell>
                            <TableCell className="field" colSpan={5}>{orginfo.address}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="label" style={{ width: 210}}>邮编</TableCell>
                            <TableCell className="field" colSpan={5}>{orginfo.postcode}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="label" style={{ width: 210}}>联系人</TableCell>
                            <TableCell className="field" colSpan={5}>{orginfo.contact_person}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="label" style={{ width: 210}}>联系电话</TableCell>
                            <TableCell className="field" colSpan={5}>{orginfo.contact_phone}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="label" style={{ width: 210}}>电子邮箱</TableCell>
                            <TableCell className="field" colSpan={5}>{orginfo.email}</TableCell>
                        </TableRow>

                    </TableBody>
                </Table>

                <Link to={{
                        pathname: "/organization/edit",
                        orgid: orgid
                        }}>
                    <Button variant="outlined" color="primary">编辑</Button>
                </Link>
                <br/><br/><br/>
            </div>
        </div>
    )
}

export default OrganizationView





