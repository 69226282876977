import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

const DialogFindBackPassword = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }


  const handleYes = () => {
      setOpen(false)
    }


  return (
    <>
        
        <button className="btn btn-success"
            onClick={handleClickOpen}
        >
          找回密码
        </button>
        <Dialog
            open={open}
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown={true}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  handleClose();
                } 
            }}        >
            <DialogTitle id="wxpay-qrcode-dialog-title">
                {"登录系统时遇到了问题？"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                如在注册或登录过程中遇到问题（如注册时提示“单位已经注册过”，登录时提示“单位名称或密码无效”），请将您的单位名称通过短信或微信的方式发送至手机 13401102466 （微信同号） 
                <br/><br/>例如："南洋市公安局 密码忘了"
                <br/><br/>我们通常会在一小时内与您取得联系，帮您找回密码，解决系统登录问题。（因工作关系电话有时不方便接听，建议您通过短信或微信联系客服，谢谢）
              </DialogContentText>
            </DialogContent>
            
            <DialogActions>
                <Button onClick={handleYes} autoFocus>
                    返回
                </Button>
            </DialogActions>
        </Dialog>
    </>
  );
}


export default DialogFindBackPassword