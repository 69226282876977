import axios from 'axios'
import { JPA_API_URL } from '../Constants';
import Base64 from './Base64'

class OrderDataService {
    createBasicAuthToken(username,password) {
        // return 'Basic ' + window.btoa(unescape(encodeURIComponent(username + ":" + password)))
        return 'Basic ' + Base64.encode(username + ":" + password)
    }

    retrieveAllOrders(org_id) {
        //console.log('executed service')
        return axios.get(`${JPA_API_URL}/order/list/${org_id}`, 
            {headers: {authorization: this.createBasicAuthToken(
                sessionStorage.getItem('authenticatedUser'),
                sessionStorage.getItem('authenticatedUserPassword'),
            )}});
    }

    retrieveOrder(id) {
        return axios.get(`${JPA_API_URL}/order/${id}`, 
            {headers: {authorization: this.createBasicAuthToken(
                sessionStorage.getItem('authenticatedUser'),
                sessionStorage.getItem('authenticatedUserPassword'),
            )}})
    }

    updateOrder(order) {
        //console.log('executed service')
        return axios.put(`${JPA_API_URL}/order/`, order, 
            {headers: {authorization: this.createBasicAuthToken(
                sessionStorage.getItem('authenticatedUser'),
                sessionStorage.getItem('authenticatedUserPassword'),
            )}});
    }

    createOrder(order) {
        //console.log('executed service')
        return axios.post(`${JPA_API_URL}/order/add`, order, 
            {headers: {authorization: this.createBasicAuthToken(
                sessionStorage.getItem('authenticatedUser'),
                sessionStorage.getItem('authenticatedUserPassword'),
            )}});
    }

    deleteOrderById(id){
        console.log("about to delete..." + id)
        return axios.delete(`${JPA_API_URL}/order/${id}`,
        {headers: {authorization: this.createBasicAuthToken(
            sessionStorage.getItem('authenticatedUser'),
            sessionStorage.getItem('authenticatedUserPassword'),
        )}});
    }

    getWXPayQRCode(orderNo){
        console.log('about to get wxpay qrcode')
        return axios.get(`${JPA_API_URL}/order/wxpaycode/${orderNo}`, 
            {headers: {authorization: this.createBasicAuthToken(
                sessionStorage.getItem('authenticatedUser'),
                sessionStorage.getItem('authenticatedUserPassword'),
            )}})
    }

    checkWXPayStatus(orderNo){
        console.log('about to check wxpay status')
        return axios.get(`${JPA_API_URL}/order/wxpaystatus/${orderNo}`, 
            {headers: {authorization: this.createBasicAuthToken(
                sessionStorage.getItem('authenticatedUser'),
                sessionStorage.getItem('authenticatedUserPassword'),
            )}})
    }
}

export default new OrderDataService()
